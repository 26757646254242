"use strict";

const header = document.querySelector('.header');
let prevScroll = window.scrollY;

window.addEventListener('scroll', () => {

    scroll = window.scrollY
    const windowOuterWidth = window.innerWidth;
    if (scroll > 0) {
        if (windowOuterWidth > 768) {
            header.classList.add('fixed')
        } else {
            header.classList.add('fixed_mobile')
        }

    } else {

        if (windowOuterWidth > 768) {
            header.classList.remove('fixed')
        } else {
            header.classList.remove('fixed_mobile')
        }

    }
    prevScroll = scroll;
});

